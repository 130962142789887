<template>
  <div>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      class="ma-0 rounded-t-0"
      icon="mdi-bed"
      color="danger"
      text
    >
      Du hast {{ items.length }} offene Abwesenheiten!
    </v-alert>
    <v-card
      :class="`v-alert--text danger--text rounded-t-0`"
      flat
      tile
      :loading="loading"
    >
      <v-divider />
      <v-card-actions>
        <v-btn
          dark
          color="danger"
          text
          block
          small
          :to="{ name: 'StudentAbsences' }"
          >entschuldigen</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "absence/absence",
        query: "pending",
      });
      if (this.items.length > 0) {
        this.$emit("loaded");
      }
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
